import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SvgIcon } from '@progress/kendo-react-common';
import {
  gearIcon,
  chevronLeftIcon,
  mapMarkerIcon,
  moreHorizontalIcon,
  searchIcon,
  xCircleIcon,
  fileAddIcon,
  folderAddIcon,
} from '@progress/kendo-svg-icons';
import { ReactComponent as InvisibleIcon } from '../../../assets/icons/invisible.svg';
import { Loader } from '@progress/kendo-react-indicators';
import {
  FileNode,
  FileNodeType,
  FileNodeStatus,
  FileNodeGeolocation,
  DOWNLOAD_SIZE_WARNING,
  FileNodeViewType,
} from '../../../types/DataDelivery';
import RenameLayerModal from './RenameLayerModal';
import DeleteLayerModal from './DeleteLayerModal';
import { ShareResourceButton, SubmitButton } from '../../../components/form';
import Tooltipped from '../../../components/TooltippedV2';
import DownloadButton from '../../Dashboard/DownloadButton';
import { useUser } from '../../../hooks/authentication';
import { AuthorityLevel, AuthorityType, UserRole } from '../../../types';
import ContextMenu from '../../../components/filenodes/ContextMenu';
import PropertiesPanel from '../../../components/filenodes/PropertiesPanel';
import NavigationOptions from './NavigationOptionsDialog';
import DownloadWarningModal from '../../../components/filenodes/DownloadWarningModal';
import FileUploaderModal from '../../../components/filenodes/FileUploader';
import { findFileNodeRecursively, getFileNodeTag } from '../../../common/fileNodeHelper';
import DataDeliveryNavigationOptions from '../../../types/DataDelivery/FileNodeNavigationOptions';
import { useFileNodeUpdate } from '../../../hooks/data_delivery';
import { OverlayedLoader, WrappedOrEllipsisSpan } from '../../../components';
import CreateRootFileNodeModal from '../../../components/filenodes/CreateFolderFileNodeModal';
import MoveFileNodeModal from '../../../components/filenodes/MoveFileNodeModal';
import { useAppContext } from '../../../context/app';
import { set } from 'lodash';

const LayersContainer = styled.div`
  background: white;
  width: var(--geosap-datadelivery-navigation-width);
  overflow: hidden;
`;

const LayerElement = styled.div`
  position: relative;
  color: 'rgb(116,116,116)',
  background: white;
  padding: 16px 24px;
  display: flex;
  font-size: 0.85rem;
  justify-content: center;
  align-items: center;
`;

const NavigationSearch = styled.div`
  display: flex;
  align-items: center;
  border-radius: 24px;
  border: 3px solid var(--geosap-steps-background);
  padding: 4px;
  flex: 1;
`;

interface NavigationSidebarProps {
  loading: boolean;
  rootFileNode: FileNode;
  selectedFileNode: FileNode;
  openedFileNode: FileNode;
  hoveredFileNode: FileNode;
  focusedFileNode: FileNode;
  rootId: string;
  searchText: string;
  navigationOptions: DataDeliveryNavigationOptions;
  onUpdateNavigationOptions(options: DataDeliveryNavigationOptions): void;
  onProcess(fileNode: FileNode): void;
  onUpdateFileNode(fileNode: FileNode): void;
  onUpdateFileNodeGeolocation(fileNode: FileNode): void;
  onCleanFileNode(fileNode: FileNode): void;
  onCreateFolderFileNode(name: string, parentFileNode: FileNode): void;
  onDeleteFileNode(fileNode: FileNode): void;
  onForgetFileNode(fileNode: FileNode): void;
  onViewFileNode(FileNode: any): void;
  onZoomToFileNode(FileNode: any): void;
  onSelectFileNode(FileNode: any): void;
  onDownloadFileNode(FileNode: any): void;
  onHoverFileNode(FileNode: any): void;
  onSearchTextChange(searchText: string): void;
  onUploadFileNode(file: File, parentFileNode: FileNode): void;
  onBack(): void;
}

const NavigationSidebar: React.FC<NavigationSidebarProps> = (props: NavigationSidebarProps) => {
  const { screenWidth, dispatch } = useAppContext();
  const [confirmingDownloadFileNode, setConfirmingDownloadFileNode] = useState<FileNode>(null);
  const [showProperties, setShowProperties] = useState(false);
  const [fileNodeProperties, setFileNodeProperties] = useState<FileNode>(null);
  const [contextMenuFileNode, setContextMenuFileNode] = React.useState(null);
  const [contextOffset, setContextOffset] = React.useState(null);
  const [creatingFolderFileNode, setCreatingFolderFileNode] = useState(false);
  const [movingFileNode, setMovingFileNode] = useState(null);
  const [uploadingFileNode, setUploadingFileNode] = useState(false);
  const [renamingLayer, setRenamingLayer] = React.useState(null);
  const [deletingFileNode, setDeletingFileNode] = React.useState(null);
  const [showOptions, setShowOptions] = React.useState(false);
  const { rootId } = useParams();
  const rootDataDelivery = rootId === 'root';
  const { userHasAuthority, userHasRole, getOrgSubscriptionValid, isViewAuthenticated } = useUser();
  const filenodeUpdateMutation = useFileNodeUpdate();

  useEffect(() => {
    document.addEventListener('click', () => {
      if (contextMenuFileNode) {
        setContextMenuFileNode(null);
      }
    });
  }, [contextMenuFileNode]);

  useEffect(() => {
    if (props.openedFileNode) {
      // Root data already exists, go about replacing.
      let newFileNodeProperties = null;
      let newContextMenuFileNode = null;
      if (fileNodeProperties) {
        newFileNodeProperties = findFileNodeRecursively(props.openedFileNode, fileNodeProperties.id);
      }
      if (contextMenuFileNode) {
        newContextMenuFileNode = findFileNodeRecursively(props.openedFileNode, contextMenuFileNode.id);
      }
      setFileNodeProperties(newFileNodeProperties);
      setContextMenuFileNode(newContextMenuFileNode);
    }
  }, [props.openedFileNode]);

  const createFolderFileNode = (fileNodeName: string) => {
    props.onCreateFolderFileNode(fileNodeName, props.openedFileNode);
  };

  const renderFileNodeTag = (fileNode: FileNode) => {
    if (fileNode.status === FileNodeStatus.PROCESSING) {
      return (
        <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
          <Loader type={'converging-spinner'} size="small" themeColor={'info'} />
        </div>
      );
    }
    if (fileNode.status === FileNodeStatus.ERRORED_IN_PROCESSING || fileNode.status === FileNodeStatus.INVALID) {
      return (
        <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
          <div
            style={{ marginRight: '8px', position: 'relative' }}
            onClick={(e) => {
              e.stopPropagation();
              setContextMenuFileNode(fileNode);
              return true;
            }}
          >
            <a
              style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
              title={fileNode.errorMessage ? fileNode.errorMessage : "Error occured during this file's processing."}
            ></a>
            <SvgIcon
              icon={xCircleIcon}
              size="xlarge"
              style={{ color: 'var(--geosap-light-error)', position: 'relative' }}
            ></SvgIcon>
          </div>
        </Tooltip>
      );
    }
    if (fileNode.isDir) {
      return <SvgIcon icon={mapMarkerIcon} size="xlarge" color={fileNode.color} />;
    }
    const tag = getFileNodeTag(fileNode);
    if (!tag) return null;
    return (
      <span
        style={{
          padding: '4px 10px',
          borderRadius: 12,
          backgroundColor: tag.background,
          color: 'white',
          height: 'fit-content',
          fontSize: '1.01rem',
        }}
      >
        {tag.text}
      </span>
    );
  };

  const renderFileNodeElement = (fileNode: FileNode, index: number) => {
    const selectable =
      fileNode.isDir || fileNode.status === FileNodeStatus.READY || fileNode !== props.selectedFileNode;
    const selected = fileNode === props.selectedFileNode;
    const hovered = fileNode === props.hoveredFileNode;
    const backgroundColor = selected
      ? 'var(--geosap-selected)'
      : hovered
      ? 'var(--geosap-input-border-color)'
      : 'white';
    const color = selected || hovered ? 'white' : 'rgb(116,116,116)';
    if (
      !props.navigationOptions?.showSupport &&
      (fileNode.isSupport ||
        fileNode.fileType === FileNodeType.OTHER ||
        fileNode.fileType === FileNodeType.SUPPORT ||
        fileNode.fileType === FileNodeType.SHAPEFILE_ATTR ||
        fileNode.fileType === FileNodeType.SHAPEFILE_INDEX ||
        fileNode.fileType === FileNodeType.SHAPEFILE_PROJECTION)
    )
      return null;
    if (rootDataDelivery && fileNode.id === '00000000-0000-0000-0000-000000000000') return null;
    return (
      <LayerElement
        key={index}
        className="animated-background animated-color"
        style={{
          background: backgroundColor,
          color: color,
          cursor: selectable ? 'pointer' : 'default',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        }}
        onClick={() => {
          props.onSelectFileNode(fileNode);
        }}
        onMouseEnter={() => {
          console.log();
          props.onHoverFileNode(fileNode);
        }}
        onMouseLeave={() => {
          console.log();
          props.onHoverFileNode(null);
        }}
      >
        {renderFileNodeTag(fileNode)}
        <div style={{ flex: 1, overflow: 'hidden' }}>
          <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
            <Typography.h4
              style={{
                position: 'relative',
                padding: '0px 12px',
                display: 'flex',
                fontSize: '1.3rem',
                marginBottom: 0,
              }}
            >
              <a style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }} title={fileNode.name}></a>

              <WrappedOrEllipsisSpan wrapped={true}>{fileNode.name}</WrappedOrEllipsisSpan>
            </Typography.h4>
          </Tooltip>
        </div>
        {userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE) &&
          fileNode.viewTypes &&
          !fileNode.viewTypes.includes(FileNodeViewType.MAP) && (
            <div
              style={{ display: 'flex', alignItems: 'center', position: 'relative', marginRight: '1rem' }}
              onClick={(e) => {
                e.stopPropagation();
                const newViewTypes = [...fileNode.viewTypes, FileNodeViewType.MAP];
                filenodeUpdateMutation.mutateAsync({ fileNodeId: fileNode.id, viewTypes: newViewTypes });
                return true;
              }}
            >
              <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                <InvisibleIcon
                  fill={'#eaeaea'}
                  stroke={'#eaeaea'}
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                />
                <a
                  style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                  title={'Enable this layer.'}
                ></a>
              </Tooltip>
            </div>
          )}
        <div
          style={{ display: 'flex', alignItems: 'center', paddingTop: '4px' }}
          onClick={(e) => {
            e.stopPropagation();
            setContextOffset({ left: e.clientX, top: e.clientY });
            setContextMenuFileNode(fileNode);
            return true;
          }}
        >
          <SvgIcon icon={moreHorizontalIcon} size="large" />
        </div>
      </LayerElement>
    );
  };

  let style: React.CSSProperties = { position: 'absolute', left: 20, top: 20, bottom: 20, zIndex: 1, borderRadius: 24 };
  if (screenWidth < 922) {
    style = { width: '100%', height: '300px', zIndex: 1, borderRadius: 0 };
  }

  return (
    <LayersContainer className="d-flex flex-column animated-left" style={style}>
      <DownloadWarningModal
        filenode={confirmingDownloadFileNode}
        show={confirmingDownloadFileNode !== null}
        handleClose={() => {
          setConfirmingDownloadFileNode(null);
        }}
        onConfirm={() => {
          props.onDownloadFileNode(confirmingDownloadFileNode);
          setConfirmingDownloadFileNode(null);
        }}
      />
      {
        <ContextMenu
          show={contextMenuFileNode !== null}
          fileNode={contextMenuFileNode}
          offset={contextOffset}
          onViewFileNode={props.onViewFileNode}
          onZoomToFileNode={(filenode) => {
            setContextMenuFileNode(null);
            props.onZoomToFileNode(filenode);
          }}
          onDownloadFileNode={() => {
            if (contextMenuFileNode.size && contextMenuFileNode.size > DOWNLOAD_SIZE_WARNING) {
              setConfirmingDownloadFileNode(contextMenuFileNode);
              setContextMenuFileNode(null);
            } else {
              props.onDownloadFileNode(contextMenuFileNode);
            }
          }}
          onProcessFileNode={props.onProcess}
          onUpdateFileNode={props.onUpdateFileNode}
          onUpdateFileNodeGeolocation={props.onUpdateFileNodeGeolocation}
          onCleanFileNode={props.onCleanFileNode}
          onMoveFileNode={(fileNode: FileNode) => {
            setMovingFileNode(fileNode);
          }}
          onForgetFileNode={props.onForgetFileNode}
          onDeleteFileNode={(fileNode: FileNode) => {
            setDeletingFileNode(fileNode);
          }}
          onPropertiesFileNode={(filenode) => {
            setContextMenuFileNode(null);
            setShowProperties(true);
            setFileNodeProperties(filenode);
          }}
        ></ContextMenu>
      }
      <PropertiesPanel
        show={showProperties}
        onClose={() => {
          setShowProperties(false);
        }}
        rootFileNode={props.rootFileNode}
        fileNode={fileNodeProperties}
      />
      {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
        <NavigationOptions
          show={showOptions}
          handleClose={() => {
            setShowOptions(false);
          }}
          currentNavigationOptions={props.navigationOptions}
          onNavigationOptionsChange={props.onUpdateNavigationOptions}
        ></NavigationOptions>
      )}

      {/* HEADER */}
      <div
        className="d-flex flex-column"
        style={{ color: 'rgb(190,190,190)', background: 'rgb(116,116,116)', padding: '1rem 2rem' }}
      >
        <div className="d-flex items-center" style={{ alignItems: 'center', flex: 1 }}>
          {!rootDataDelivery && (
            <SvgIcon
              icon={chevronLeftIcon}
              size="large"
              style={{
                color: 'rgb(190,190,190)',
                marginRight: '12px',
                cursor: 'pointer',
                fontSize: '28px',
                marginLeft: '-8px',
              }}
              onClick={() => {
                props.onBack();
              }}
            />
          )}
          <NavigationSearch>
            {props.searchText !== null && props.searchText !== '' ? (
              <SvgIcon
                icon={xCircleIcon}
                size="medium"
                style={{ color: 'rgb(190,190,190)', margin: '0px 12px', cursor: 'pointer' }}
                onClick={() => {
                  props.onSearchTextChange('');
                }}
              />
            ) : (
              <SvgIcon
                icon={searchIcon}
                size="medium"
                style={{ color: 'rgb(190,190,190)', margin: '0px 12px', cursor: 'pointer' }}
              />
            )}

            <input
              type={'text'}
              name={''}
              placeholder={
                screenWidth > 992 ? 'Search by location, project name or data type' : props.rootFileNode?.name
              }
              className="searchbox-input placeholder-hidden-on-focus"
              style={{ width: '100%', color: 'rgb(190,190,190)', background: 'transparent', border: 'none' }}
              onChange={(e) => {
                props.onSearchTextChange(e.target.value);
              }}
              value={props.searchText}
            />
          </NavigationSearch>
        </div>
        <Typography.h3
          className="d-none d-lg-flex"
          style={{
            color: 'rgb(190,190,190)',
            padding: '24px 0px',
            display: 'flex',
            justifyContent: props.rootFileNode?.status === FileNodeStatus.CREATED ? 'space-between' : 'flex-start',
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ position: 'relative', flex: '1 1 0%', display: 'flex', overflow: 'hidden' }}>
              <WrappedOrEllipsisSpan wrapped={true}>{props.rootFileNode?.name}</WrappedOrEllipsisSpan>

              <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                  <a
                    style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
                    title={props.rootFileNode?.name}
                  ></a>
                </Tooltip>
              </div>
            </div>
            {!rootDataDelivery && userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
              <Tooltipped
                containerStyle={{
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                label={'Upload a new file.'}
                onClick={() => {
                  /*if (!getOrgSubscriptionValid()) {
                    dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
                    return true;
                  }*/
                  setUploadingFileNode(true);
                  return true;
                }}
              >
                <SvgIcon
                  icon={fileAddIcon}
                  size="medium"
                  style={{ fontSize: '1.2rem', position: 'relative' }}
                ></SvgIcon>
              </Tooltipped>
            )}
            {!rootDataDelivery && userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
              <Tooltipped
                containerStyle={{
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                label={'Create a new folder.'}
                onClick={() => {
                  /*if (!getOrgSubscriptionValid()) {
                    dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
                    return true;
                  }*/
                  setCreatingFolderFileNode(true);
                  return true;
                }}
              >
                <SvgIcon
                  icon={folderAddIcon}
                  size="medium"
                  style={{ fontSize: '1.2rem', position: 'relative' }}
                ></SvgIcon>
              </Tooltipped>
            )}
            {!rootDataDelivery && (
              <DownloadButton
                style={{ marginLeft: '0.5rem' }}
                iconStyle={{ color: 'rgb(190,190,190)' }}
                iconHoveredStyle={{ color: 'rgb(255,255,255)' }}
                downloading={false}
                onDownload={() => {
                  /*if (!getOrgSubscriptionValid() && !isViewAuthenticated()) {
                    dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
                    return;
                  }*/
                  if (props.rootFileNode.size && props.rootFileNode.size > DOWNLOAD_SIZE_WARNING) {
                    setConfirmingDownloadFileNode(props.rootFileNode);
                  } else {
                    props.onDownloadFileNode(props.rootFileNode);
                  }
                }}
                disabled={false}
                message={'Download'}
              />
            )}
            {!rootDataDelivery && userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.SHARE) && (
              <ShareResourceButton
                resourceName={props.rootFileNode?.name}
                fileNodeId={props.rootFileNode?.id}
                disabled={false}
                style={{ marginLeft: '0.5rem' }}
                iconStyle={{ color: 'rgb(190,190,190)' }}
                iconHoveredStyle={{ color: 'rgb(255,255,255)' }}
              ></ShareResourceButton>
            )}
            {!rootDataDelivery && userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
              <Tooltipped
                containerStyle={{
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                label={'Display navigation options.'}
                onClick={() => {
                  /*if (!getOrgSubscriptionValid()) {
                    dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
                    return true;
                  }*/
                  setShowOptions(true);
                  return true;
                }}
              >
                <SvgIcon icon={gearIcon} size="medium" style={{ fontSize: '1.2rem', position: 'relative' }}></SvgIcon>
              </Tooltipped>
            )}
          </div>
          {!rootDataDelivery && props.rootFileNode?.status === FileNodeStatus.CREATED && (
            <SubmitButton
              label="Process"
              size="small"
              full={false}
              onClick={() => {
                props.onProcess(props.rootFileNode);
              }}
              subscriptionRequired={true}
            ></SubmitButton>
          )}
        </Typography.h3>

        {props.openedFileNode && props.openedFileNode.id !== props.rootFileNode.id && (
          <Typography.h5
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              flex: 1,
              padding: '0px 12px',
            }}
          >
            {props.openedFileNode.name}
          </Typography.h5>
        )}
      </div>
      <div style={{ height: '100%', overflow: 'auto' }}>
        {props.loading && (
          <div style={{ width: '100%', padding: '1rem' }}>
            <Loader size="medium" style={{ color: 'var(--view-green-highlight)' }} />
          </div>
        )}
        {!props.loading &&
          props.openedFileNode?.children?.map((childFileNode, index) => renderFileNodeElement(childFileNode, index))}
      </div>

      {userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE) && (
        <CreateRootFileNodeModal
          show={creatingFolderFileNode}
          handleClose={() => {
            setCreatingFolderFileNode(false);
          }}
          defaultValue="New Project"
          onConfirm={(name: string) => {
            setCreatingFolderFileNode(false);
            createFolderFileNode(name);
          }}
          parent={props.openedFileNode}
        />
      )}

      {userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE) && (
        <MoveFileNodeModal
          show={movingFileNode !== null}
          handleClose={() => {
            setMovingFileNode(null);
          }}
          fileNode={movingFileNode}
        />
      )}

      <RenameLayerModal
        show={renamingLayer !== null}
        handleClose={() => {
          setRenamingLayer(null);
        }}
        defaultValue={renamingLayer?.displayName}
        onConfirm={(value: string) => {
          //props.onRenameLayer(renamingLayer, value);
          setRenamingLayer(null);
        }}
      />
      <DeleteLayerModal
        show={deletingFileNode !== null}
        handleClose={() => {
          setDeletingFileNode(null);
        }}
        fileNode={deletingFileNode}
        onConfirm={() => {
          props.onDeleteFileNode(deletingFileNode);
          setDeletingFileNode(null);
        }}
      />
      {uploadingFileNode && (
        <FileUploaderModal
          show={uploadingFileNode}
          handleClose={() => {
            setUploadingFileNode(false);
          }}
          parentFileNode={props.openedFileNode}
          onConfirm={(files: File[]) => {
            setUploadingFileNode(false);
            files.forEach((file: File) => {
              props.onUploadFileNode(file, props.openedFileNode);
            });
          }}
        />
      )}
    </LayersContainer>
  );
};

export default NavigationSidebar;
